<template>
	<div class="Index">
		<div class="banner" :style="{'background-image': 'url('+imgUrl+')'}">
			<div class="row">
				<div class="widthBan rounded-circle d-flex flex-column justify-content-center align-items-center">
					<div class="widthCir rounded-circle text-center d-flex flex-column justify-content-center">
						<h1 class="fons mb-3">让拆迁充满智慧</h1>
						<p>资金无忧，不再为融资发愁</p>
						<p>委托处置，卖的更快价更高</p>
						<p>管理保全，财、物管理从此清晰，提升管理效率</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 拆迁行业现状痛点 -->
		<div class="status widthMin mb-5">
			<h2 class="text-center mb-2 pt-5 titleSC">拆迁行业现状痛点</h2>
			<h4 class="text-center mb-4 titleEng">Pain spot</h4>
			<div class="row">
				<div class="col-md-4 item">
					<div class="pl-md-3">
						<h5 class="mb-4">拆迁行业太 <span>难</span> 了</h5>
						<ul>
							<li>资金缺口大，压力太大 </li>
							<li>找不到正规融资渠道，只能找民间资金众筹</li>
							<li>既要拆，还要管拆下来的设备</li>
							<li>既要管，还要联系人买设备</li>
							<li>既要卖，还要记好每-笔账</li>
							<li>既要记，还要每个让大小股东查账，认可</li>
							<li>适合行业的管理软件没有</li>
							<li>太专业的软件用不了</li>
						</ul>
					</div>
				</div>
				<div class="col-md-4 item2">
					<div>
						<div class="text-center title mb-3 mt-3 ">融资难</div>
						<ul class="row">
							<li class="col-md-6 mb-3 pr-0">融资不稳定</li>
							<li class="col-md-6 mb-3 pr-0">下款额度低</li>
							<li class="col-md-6 mb-3 pr-0">缺资金</li>
							<li class="col-md-6 mb-3 pr-0">正规渠道难</li>
							<li class="col-md-6 mb-3 pr-0">众筹模式多,不可持续</li>
							<li class="col-md-6 mb-3 pr-0">繁琐</li>
							<li class="col-md-6 mb-3 pr-0">抵押物只认房车</li>
							<li class="col-md-6 mb-3 pr-0">下款慢</li>
							<li class="col-md-6 mb-3 pr-0">资金缺口较大</li>
						</ul>
					</div>
				</div>
				<div class="col-md-4 item2">
					<div class="pr-md-3">
						<div class="text-center title mb-3 mt-3 ">管理难</div>
						<ul class="row">
							<li class="col-md-6 mb-3 pr-0">融资不稳定</li>
							<li class="col-md-6 mb-3 pr-0">股东较多</li>
							<li class="col-md-6 mb-3 pr-0">人工记单</li>
							<li class="col-md-6 mb-3 pr-0">账目类多</li>
							<li class="col-md-6 mb-3 pr-0">没有专业的软件</li>
							<li class="col-md-6 mb-3 pr-0">进出资金多、大</li>
							<li class="col-md-6 mb-3 pr-0">太复杂软件用不了</li>
							<li class="col-md-6 mb-3 pr-0">股权分散</li>
							<li class="col-md-6 mb-3 pr-0">信息不透明</li>
							<li class="col-md-6 mb-3 pr-0">资产管理混乱</li>
						</ul>
					</div>
					
				</div>
			</div>
		</div>
		<div class="bg-sha">
		
			<!-- tips -->
			<div class="tips-box pb-3">
				<h2 class="text-center">SMART PART</h2>
				<p class="text-center">让拆迁行业充满智慧</p>
				<img src="../../assets/images/web_h5/index_1.png" class="mx-auto d-block mt-3 mb-3" >
			</div>
			<!-- 智慧拆迁解决方案 -->
			<div class="status widthMin mb-4">
				<h2 class="text-center mb-2 titleSC ">智慧拆迁解决方案</h2>
				<h4 class="text-center mb-4 titleEng">Pain spot</h4>
				<div class="card-deck">
				  <div class="card blueZ shadow position-relative" @mouseenter="enter(1)" @mouseleave="leave()">
					<div class="card-body text-white text-center">
						<div class="">
							<h5 class="card-title text-white pt-md-5 pb-md-4 mb-4">拆迁物抵押融资服务</h5>
							<p class="card-text mb-4">以拆迁的高价值资产作为抵押物，为融配资双</p>
							<p>解决拆迁客户融资难，金额低，融资渠道不稳定，拆迁物无法抵押的难题</p>
						</div>
					</div>
					<transition name="fade">
						<div :style="{'background-image': 'url('+rz_index_13+')'}" class="position-absolute bg-staImg1 text-center d-flex flex-column align-items-center justify-content-center" v-if="index!=1">
							<img src="../../assets/images/web_h5/index_2.png" class="mb-5" >
							<h5 class="text-white ">拆迁物抵押融资服务</h5>
						</div>
				    </transition>
				  </div>
				  <div class="card blueZ shadow"  @mouseenter="enter(2)" @mouseleave="leave()">
					<div class="card-body text-white text-center">
					   <h5 class="card-title text-white pt-md-5 pb-md-4 mb-4">委托处置服务</h5>
					   <p class="card-text mb-4">以拆迁的高价值资产作为抵押物，为融配资双</p>
					   <p>解决拆迁客户融资难，金额低，融资渠道不稳定，拆迁物无法抵押的难题</p>
					</div>
					<transition name="fade">
						<div :style="{'background-image': 'url('+rz_index_14+')'}" class="position-absolute bg-staImg2 text-center d-flex flex-column align-items-center justify-content-center" v-if="index!=2">
							<img src="../../assets/images/web_h5/index_2.png" class="mb-5" >
							<h5 class="text-white ">委托处置服务</h5>
						</div>
					</transition>
				  </div>
				  <div class="card blueZ shadow" @mouseenter="enter(3)" @mouseleave="leave()"> 
					<div class="card-body text-white text-center">
						<h5 class="card-title text-white pt-md-5 pb-md-4 mb-4">管理保全服务</h5>
						<p class="card-text  mb-4">好多资产平台为双方提供资产管理及保全服务</p>
						<p class="mb-4">拆迁方：拆迁可视化管理，资产管理、资金账目清晰，大幅度提升管理效率，助力拆迁作业</p>
						<p>出资方：结合RFID、GPS、定位器、智能道闸等智能设备，对抵押物进行保全，确保未通过平台收款的抵押物无法脱离</p>
					</div>
					<transition name="fade">
						<div :style="{'background-image': 'url('+rz_index_16+')'}" class="position-absolute bg-staImg3 text-center d-flex flex-column align-items-center justify-content-center" v-if="index!=3">
							<img src="../../assets/images/web_h5/index_2.png" class="mb-5" >
							<h5 class="text-white ">管理保全服务</h5>
						</div>
					</transition>
				  </div>
				</div>
			</div>
			<!-- 融资 -->
			
			<div class="widthMin getMoney">
				<!-- tips -->
				<div class="row  pt-5 mb-4 wm-center">
					<div class="col-md-5">
						<div class=" tips-box pb-3">
							<h2 class="text-center">SMART PART</h2>
							<p class="text-center">帮助拆迁项目方快速融资，减小拆迁项目的资</p>
							<img src="../../assets/images/web_h5/index_1.png" class="mx-auto d-block mt-3 mb-3" >
						</div>
						<h5 class="text-center titleSC">智慧融配资服务</h5>
					</div>
				</div>
				<div class="row wm-center">
					<div class=" col-md-7 col-lg-5 ">
						<div class="bg-card pt-4 shadow">
							<div class="d">
								<div class="titleBtn text-center mb-4">传统融资渠道</div>
								<ul class="pl-4 pr-4">
									<li class="mb-2">可申请融资金额较小</li>
									<li class="mb-2">只认房车，拆迁设备等资产无法抵押</li>
									<li class="mb-2">手续较多，办理麻烦</li>
									<li class="mb-2">审批繁琐且难，且下款较慢</li>
									<li class="mb-2">融资渠道可持续性差，不稳定</li>
								</ul>
							</div>
							<div class="text-center mg-7">
								<img :src="rz_index_3" >
							</div>
							<div class="pb-5 bg-money mg-14 pt-5 text-white" :style="{'background-image': 'url('+rz_index_12+')'}" >
								<!-- <img src="./image/编组%2043.png" class="bg-mony position-absolute"> -->
								<div class="pt-5">
									<div class="titleBtn text-center cBlue mb-4 mt-lg-5">智慧融资渠道</div>
									<ul class="pl-4 pr-4">
										<li class="mb-2">拆迁设备等资产可作为抵押物</li>
										<li class="mb-2">超高额度，最高能达到抵押物评估价的70%</li>
										<li class="mb-2">手续、流程少，审批速度快</li>
										<li class="mb-2">专业出资机构，稳定可靠可持续</li>
									</ul>
								</div>	
							</div>
						</div>
					</div>
					<div class="col-md-7 col-lg-7">
						<!-- tips -->
						<div class="tips-box mt-2 ">
							<h2 class="text-center">SMART PART</h2>
							<p class="text-center">帮助拆迁项目方快速融资，减小拆迁项目的资</p>
							<img src="../../assets/images/web_h5/index_1.png" class="mx-auto d-block mt-3 mb-3" >
						</div>
						<h5 class="text-center mb-4 titleSC ">智慧处置收款服务</h5>
						<div class="bg-monry2 " :style="{'background-image': 'url('+rz_index_8+')'}">
							<div class="p-5">
								<h5 class="fon3 mb-4">帮出资方保全本金及利息</h5>
								<p>抵押物变卖无论线上线下何种方式变卖，必须通过平台完成付款 </p>
								<p>付款后按双方约定自动扣除应还款金额，保证本金安全及利息</p>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- step -->
		<div class="steps widthMin mb-5 pb-3 mt-5">
			<div class="d-flex justify-content-center position-relative  mb-3">
				<div class="steps-btn ">现在去融资</div>
				<hr class="position-absolute srep-hr">
			</div>
			<h5 class="text-center">简单填写项目资料、即可完成申请</h5>
		</div>
		<!-- tips -->
		<div class="tips-box pb-2 pt-4">
			<h2 class="text-center">SMART PART</h2>
			<p class="text-center">帮拆迁项目卖得更快、卖价更高、更省心</p>
			<img src="../../assets/images/web_h5/index_1.png" class="mx-auto d-block mt-3 mb-3" >
		</div>
		<!-- 帮拆迁方处置 -->
		<div class="helper widthMin" >
			<h2 class="text-center mb-5 titleSC">帮拆迁方处置</h2>
			<div class="card-deck hel-wrap">
			  <div class="card">
				 <div class="position-relative">
					 <img :src="rz_index_5" class="card-img-top" alt="...">
					 <div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
						海量买家资源
					 </div>
				 </div>
				<ul class="card-body">
				  <li class="card-text mb-2 "><img src="../../assets/images/web_h5/index_6.png" class="mr-2"/> 30多万买家资源</li>
				  <li class="card-text mb-2"><img src="../../assets/images/web_h5/index_6.png" class="mr-2" />笼络全网80%回收需求信息</li>
				  <li class="card-text"><img src="../../assets/images/web_h5/index_6.png" class="mr-2" />专业回收合作6000多家</li>
				</ul>
			  </div>
			  <div class="card">
			   <div class="position-relative">
				 <img :src="rz_index_7" class="card-img-top" alt="...">
				 <div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
					线上线下，多管齐下					
				 </div>
				</div>
			   <ul class="card-body">
				 <li class="card-text mb-2 "><img src="../../assets/images/web_h5/index_6.png" class="mr-2" />组织买家，线下现场看货</li>
				 <li class="card-text mb-2"><img src="../../assets/images/web_h5/index_6.png" class="mr-2" />线上组织处置会，匹配精准买家</li>
				 <li class="card-text"><img src="../../assets/images/web_h5/index_6.png" class="mr-2" />依托买家资源库，精准撮合</li>
			   </ul>
			  </div>
			  <div class="card">
				<div class="position-relative">
					 <img :src="rz_index_15" class="card-img-top" alt="...">
					 <div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
						快人一步，价更高					
					 </div>
				 </div>
				<ul class="card-body">
				  <li class="card-text mb-2 "><img src="../../assets/images/web_h5/index_6.png" class="mr-2" /> 拆除前组织预售会</li>
				  <li class="card-text mb-2"><img src="../../assets/images/web_h5/index_6.png" class="mr-2"/>拍卖模式，价高者得</li>
				  <li class="card-text"><img src="../../assets/images/web_h5/index_6.png" class="mr-2"/>拆下后，买家直接拉走</li>
				</ul>
			  </div>
			</div>
			
		</div>
		<!-- step -->
		<div class="steps widthMin mb-5 pb-3 mt-5">
			<div class="d-flex justify-content-center position-relative  mb-3">
				<div class="steps-btn ">现在去融资</div>
				<hr class="position-absolute srep-hr">
			</div>
			<h5 class="text-center cgrey">简单填写项目资料、即可完成申请</h5>
		</div>
		<!-- tips -->
		<div class="tips-box pt-4 pb-2">
			<h2 class="text-center">SMART PART</h2>
			<p class="text-center">帮拆迁项目卖得更快、卖价更高、更省心</p>
			<img src="../../assets/images/web_h5/index_1.png" class="mx-auto d-block mt-3 mb-3" >
		</div>
		<!-- 智慧管理保全服务 -->
		<div class="helper widthMin mb-5" >
			<h2 class="text-center mb-5 titleSC">智慧管理保全服务</h2>
			<div class="row row-cols-1 row-cols-lg-2">
			  <div class="col mb-4 ">
			    <div class="card shadow border-0 p-3">
			      <div class="card-body ">
			        <h5 class="card-title font24 d-flex align-items-center  mb-4"><img src="../../assets/images/web_h5/index_9.png" class="hp-icon mr-2">抵押物保全</h5>
			        <p class="card-text mb-4">未经平台收款或变卖的资产出库，系统将会自动报警  </p>
					<p class="card-text mb-4">最小5秒/次对在库资产自动进行实时盘点，第一时间发现抵押物异常变动未经平台允许出库的资产在道闸处将会自动拦截，无法出库</p>
					<p class="card-text">资产变动出现风险信息，自动报警并锁死区域，工作人员第-时间前往处理记录所有的进出车辆</p>
			      </div>
			    </div>
			  </div>
			  <div class="col mb-4">
			    <div class="card shadow border-0 p-3 ">
			      <div class="card-body">
			        <h5 class="card-title font24 d-flex align-items-center  mb-4"><img src="../../assets/images/web_h5/index_10.png" class="hp-icon mr-2">让拆迁管理变得简单</h5>
			        <p class="card-text mb-4">可视化资产管理中心，支持小程序、电脑端 </p>
					<p class="card-text mb-4">资产管理可视化，所有资产是否在库，所在位置清晰可见 </p>
					<p class="card-text">资金管理可视化，所有账目清晰罗列，所有股东及管理者均可查看简单好用，不需要专业知识，不需要单独管理，-切就是如此简单所有资产进出记录，清晰可见，定期盘点，保证资产安全</p>
			      </div>
			    </div>
			  </div>
			</div>
			
		</div>
		<!-- 您需要智慧+拆迁服务顾问提专业咨询么？ -->
		<div class="question text-center bg-qu text-white" :style="{'background-image': 'url('+rz_index_11+')'}">
			<h1 class="font4 pt-5 mb-3">您需要智慧+拆迁服务顾问提专业咨询么？</h1>
			<h5 class="mb-5">如果您需要融资，有出资意向，或者想与我们</h5>
			<div class="mb-4">
				<button class="cancelBtn mr-5 text-white">残忍拒绝</button>
				<button class="linkBtn text-white">联系我们</button>
			</div>
			<p class="pb-4">智慧+赋能 提升价值</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				index:'',
				imgUrl: this.imgBasUrl2+'banner.png',
				rz_index_3:this.imgBasUrl2+'rz_index_3.png',
				rz_index_5:this.imgBasUrl2+'rz_index_5.png',
				rz_index_7:this.imgBasUrl2+'rz_index_7.png',
				rz_index_8:this.imgBasUrl2+'rz_index_8.png',
				rz_index_11:this.imgBasUrl2+'rz_index_11.png',
				rz_index_12:this.imgBasUrl2+'rz_index_12.png',
				rz_index_13:this.imgBasUrl2+'rz_index_13.png',
				rz_index_14:this.imgBasUrl2+'rz_index_14.png',
				rz_index_15:this.imgBasUrl2+'rz_index_15.png',
				rz_index_16:this.imgBasUrl2+'rz_index_16.png',
			}
		},
		methods: {
			enter(index) {
				this.index=index;
			},
			leave(){
				this.index = '';
			}
		},
	};
</script>

<style>
	@import url('../../assets/css/web_h5/index.css');
	.fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s;
    }
    .fade-enter,
    .fade-leave-to
    /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
</style>